<template>
    <div>
        <h1>Classic Road Racing Calendar</h1>
        <Paginator :rows="1"
                   v-model:first="currentYearIndex"
                   :totalRecords="currentYear + 1"
                   template="PrevPageLink CurrentPageReport NextPageLink"
                   currentPageReportTemplate="{currentPage}"></Paginator>
        <div class="only-new-container">
            <Checkbox v-model="showNewOnly" :binary="true" id="only-new" />
            <label for="only-new">Hide ended races</label>
        </div>
        <DataTable :value="visibleEvents"
                   sortField="startDate"
                   :sortOrder="1"
                   selectionMode="single"
                   dataKey="id"
                   @rowSelect="onRowSelect"
                   editMode="row"
                   class="p-datatable-sm"
                   responsiveLayout="scroll"
                   stripedRows>
            <Column field="track" header="Track" :sortable="true">
                <template #body="props">
                    <div class="clickableText">{{ props.data.track }}</div>
                </template>
            </Column>
            <Column field="startDate" header="Start date" :sortable="true" headerStyle="width: 130px; min-width: 130px">
                <template #body="props">
                    <div>{{ formatDateSmall(props.data.startDate) }}</div>
                </template>
            </Column>
            <Column field="endDate" header="End date" :sortable="true" headerStyle="min-width: 130px">
                <template #body="props">
                    <div>{{ formatDateSmall(props.data.endDate) }}</div>
                </template>
            </Column>
            <Column field="organizer" header="Organizer" :sortable="true"></Column>
            <Column field="classes" header="Classes" :sortable="true"></Column>
            <Column field="status" header="Status" :sortable="true"></Column>
        </DataTable>

        <Dialog :header="modalData.track" v-model:visible="modalVisible" modal>
            <div v-if="!editorVisible">
                <p>{{ formatDate(modalData.startDate) + ' - ' + formatDate(modalData.endDate) }}</p>
                <p v-if="modalData.organizer">Organizer: {{ modalData.organizer }}</p>
                <p v-if="modalData.classes">Classes: {{ modalData.classes }}</p>
                <p v-if="modalData.status">Status: {{ modalData.status }}</p>
                <p v-if="modalData.link">Driver: <a :href="modalData.link">{{ modalData.link }}</a></p>
                <p v-if="modalData.spectatorLink">Spectator <a :href="modalData.spectatorLink">{{ modalData.spectatorLink }}</a></p>
                <Card v-if="modalData.description && modalData.description.length">
                    <template #content>
                        <div v-html="modalData.description"></div>
                    </template>
                </Card>
            </div>

            <template #footer>
                <Button v-if="!editorVisible" label="Close" @click="modalVisible = false" />
            </template>
        </Dialog>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        return {
            currentYear: new Date().getFullYear(),
            events: [/*
                {id: 1, track: 'Padborg Park DK', startDate: new Date('2021-04-20'), endDate: new Date('2021-04-20'), organizer: 'Oldies Only', classes: 'Alt til og med 1983', status: 'Track day', link: 'http://crrc.dk/?contact', description: "<p>Denne beskrivelse understøtter </p><p><u>HTML formattering</u></p><p><br></p><p><strong>FED SKRIFT AAAAAAA!!!</strong></p>"},
                {id: 2, track: 'Jyllandsringen (DK)', startDate: new Date('2021-05-04'), endDate: new Date('2021-05-05'), organizer: 'AMK', classes: 'Classic', status: 'DM', link: 'http://loebskalender.dmusport.dk/index.php', description: ''},
                {id: 3, track: 'Botniaring FI', startDate: new Date('2021-05-17'), endDate: new Date('2021-05-19'), organizer: 'Kurikan Matkamotoristit', classes: 'Classic + Regularity', status: 'NO, NC, SVKMK cup', link: 'https://motti.moottoriliitto.fi/sml/sv/publicEvents', description: ''},
                {id: 4, track: 'Classic Race Aarhus', startDate: new Date('2021-05-17'), endDate: new Date('2021-05-19')},
                {id: 5, track: 'Bjergkøbing Grand Prix', startDate: new Date('2021-12-31'), endDate: new Date('2021-12-31'), organizer: 'Theodor Fælgen', classes: 'Biler åbenbart', status: 'JA!!', link: 'https://www.amk-racing.dk/tilmelding-aahus-classic-race-17-19-maj-2019/', spectatorLink: 'https://google.com', description: 'hello:)'},
            */],
            modalData: {},
            modalVisible: false,
            showNewOnly: false,
        }
    },

    mounted() {
        this.getData();
    },

    computed: {
        visibleEvents() {
            if (this.showNewOnly) {
                let now = new Date();
                return this.events.filter(d => d.endDate > now)
            }

            return this.events;
        },

        currentYearIndex: {
            get: function() {
                return this.currentYear - 1;
            },

            set: function(val) {
                this.currentYear = val + 1;
            }
        }
    },

    watch: {
        currentYear: function () {
            this.getData();
        }
    },

    methods: {
        formatDateSmall(date) {
            return date.getDate() + '/' + (date.getMonth() + 1);
        },

        formatDate(date) {
            return this.formatDateSmall(date) + '-' + date.getFullYear();
        },

        showModal(id) {
            this.modalData = this.events.find(d => d.id === id);
            if (this.modalData) {
                this.modalVisible = true;
            }
        },

        onRowSelect(event) {
            this.showModal(event.data.id);
        },

        getData() {
            axios.get('/api/event.php?year=' + this.currentYear)
            .then((response) => {
                this.events = response.data;
                this.events.forEach(event => {
                    event.startDate = new Date(event.startDate);
                    event.endDate = new Date(event.endDate);
                });
            })
            .catch(() => {
                this.$toast.add({severity:'error', summary: 'Error', detail:'Failed to load events. Please try again later'});
            })
        }
    }
}
</script>

<style lang="scss">
.clickableText {
    text-decoration: underline;
    color: #0069ff;
}

.only-new-container {
    display: flex;
    align-items: center;
    margin: 10px;

    label {
        margin-left: 5px;
        user-select: none;
        cursor: pointer;
    }
}

.p-card .p-card-content {
    padding: 0;

    div {
        :first-child {
            margin-top: 0;
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

.p-paginator {
    span {
        font-size: 50px;
    }

    .p-paginator-current {
        cursor: default;
    }
}

@media (pointer:coarse) {
    .only-new-container {
        .p-checkbox {
            width: 30px;
            height: 30px;

            .p-checkbox-box {
                width: 30px;
                height: 30px;
            }
        }

        label {
            font-size: 18px;
        }
    }
}
</style>
